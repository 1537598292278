import React from "react"

import Container from "react-bootstrap/Container"
import { Row, Col } from "react-bootstrap"

import Carousel from "react-bootstrap/Carousel"
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed"
import SEO from "../../components/seo"
import Image from "../../components/image"
import Layout from "../../components/layout"
import Paginator from "../../components/paginator"

const MegaduensEcolodgesCompetition = ({ location }) => {
  // const nextIcon = <span className="glyphicon glyphicon-glass" />

  return (
    <Layout>
      <SEO title="Luk Vermeulen" />
      <Container>
        <Carousel className="mt-3">
          <Carousel.Item>
            <Image
              filename="megadunes-ecolodges/Outside-Perspective-Rendering_1920x1080.jpg"
              alt="Architecture visualization of ecolodge in desert"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              filename="megadunes-ecolodges/Outside-Elevation-Rendering_1920x1080.jpg"
              alt="Rendered elevation of ecolodge in desert"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              filename="megadunes-ecolodges/Terrace-Perspective-Rendering.jpg"
              alt="Rendered view from the ecolodge"
            />
          </Carousel.Item>
        </Carousel>

        <div className="row mt-3">
          <div className="col-md-4">
            <dl>
              <dt>Title</dt>
              <dd>
                Megadunes Ecolodges | <small>with Thomas Berger</small>
              </dd>
              <dt>Type</dt>
              <dd>
                Competition{" "}
                <a
                  className="text-info"
                  href="https://beebreeders.com/architecturecompetitions/ecolodges/"
                >
                  see results
                </a>
              </dd>
              <dt>Awards</dt>
              <dd>Student Award + Green Award</dd>
              <dt>Year</dt>
              <dd>2020</dd>
            </dl>
          </div>
          <div className="col-md-8">
            <p></p>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <Image
              filename="megadunes-ecolodges/Section_1920x600.png"
              alt="Section of Abu Dhabi Ecolodge"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <Image
              filename="megadunes-ecolodges/Floorplan_1920x600.png"
              alt="Floorplan of Abu Dhabi Ecolodge"
            />
          </div>
        </div>

        <Carousel classNameName="">
          <Carousel.Item>
            <Image
              filename="megadunes-ecolodges/sustainability-and-technology_1920x1080.png"
              alt="Architecture visualization of ecolodge in desert"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              filename="megadunes-ecolodges/Facade-Module-Construction_1920x1080.png"
              alt="Rendered elevation of ecolodge in desert"
            />
          </Carousel.Item>
        </Carousel>

        <Paginator currentPathname={location.pathname} />
      </Container>
    </Layout>
  )
}

export default MegaduensEcolodgesCompetition
